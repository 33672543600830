import ActionAbstract from '../ActionAbstract';
import * as helper from '../../helper';

class GetDSPParameters extends ActionAbstract {

    static get ID() {
        return 'actions:faust:dsp:getParameters';
    }

    beforeCreate() {
        super.beforeCreate();

        // dynamic field prefix
        // it will be prepended to each field in order to manage various dynamic fields.
        this.prefix = 'faust_';
    }

    async run(state) {
        const type = this.getParam('type');
        //const field = this.getParam('field');
        const container = this.getParam('container');
        const value = this.getParam('value');
        const bloc = this.getParam('bloc');

        // load DSP parameters only if the DSP source is modified.
        // this avoid loading when the inspector is reload with the same DSP reference.
        if (value === bloc.custom.DSP) return false;

        const composite = { project: {} };
        const [, path] = helper.block.getEntityByIDInWholeProject(state.project, bloc.value._id, true);

        // prepare the next compose state
        composite.project[path + '.custom.DSP'] = value;

        // clear previous generated fields
        if (bloc.custom.dynamicFields) {
            Object.keys(bloc.custom.dynamicFields).forEach((key) => {
                if (key.startsWith(this.prefix)) {
                    const index = bloc.value.fields.findIndex(v => v.name === key);
                    delete bloc.custom.dynamicFields[key];
                    delete bloc.custom.fields[key];
                    if (index !== -1) bloc.value.fields.splice(index, 1);
                }
            });
        }

        // nothing left to do
        if (!value) {
            return this.composeState(state, composite);
        }

        // or generate fields from DSP parameters
        const childrens = {};
        await this.deps.mainStore.faust.listDspParams(type, value).then((params) => {
            console.log('FAUST params', params);
            const addField = (param) => {
                const field = this.getFieldDefinition(container, param);
                if (field) childrens[field.name] = field;
            };
            // list each group and parameters
            params.forEach((gop) => {
                if (!gop.type.endsWith('group')) addField(gop); // param
                else gop.items.forEach((item) => addField(item)); // group
            });
        });
        
        composite.project[path + '.custom.dynamicFields'] = childrens;
        const composed = this.composeState(state, composite);

        // @todo: should be executed on postRun (see below)
        this.trigger('block:updateFields', { id: bloc.value._id });
        return composed;
    }

    // @fixme: wait for the async run() method above to finish
    // and remove the last action this.trigger('block:updateFields') in run method
    // postRun() {
    //     const bloc = this.getParam('bloc');
    //     this.trigger('block:updateFields', { id: bloc.value._id });
    // }

    /**
     * Format the givan FAUST parameter to match the current field definition format.
     *
     * @param {string|Object} container - The field container.
     * @param {Object} param - The FAUST parameter object.
     * @returns Object or null
     */
    getFieldDefinition(container, param) {
        // exclude commons fields
        if (param.shortname === 'freq') return null;
        if (param.shortname === 'gain') return null;
        if (param.shortname === 'gate') return null;

        return this.deps.mainStore.faust.getFieldDefinition(this.prefix, container, param);
    }
}

export default GetDSPParameters;