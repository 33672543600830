/**
 *   FAUST Store instance
 *   @desc: A set of functions for obtaining information on or compiling Faust DSPs.
 */

import { EventEmitter } from 'events'
import { instantiateFaustModuleFromFile, LibFaust, FaustCompiler, FaustMonoDspGenerator } from '@grame/faustwasm';

import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)

const BASEURL = `https://${env.domain_app}/lib/FAUST/dsp`;

class FaustStore extends EventEmitter {
    constructor() {
        super();

        this.libFaust = null;
        this.baseurl = 
        this.DSPs = null;

        const loadLibFaust = async () => {
            const faustModule = await instantiateFaustModuleFromFile(`https://${env.domain_app}/lib/FAUST/faustwasm/libfaust-wasm.js`);
            this.libFaust = new LibFaust(faustModule);
            console.log(
                `%c[FAUST]%c version module ${this.libFaust.version() }%c`,
                'background: #BBDEFB; color: #FF5722; font-weight: 500',
                'font-weight: 300',
                '',
            );
        }
        loadLibFaust();
    }

    setMain(main) {
        this.main = main;
    }

    /**
     * LoadJSON
     */
    fetchJSON(url, onSuccess, onError) {
        Vue.http.get(url)
            .then(
                (response) => {
                    if (response.body.error) onError(response.body.error);
                    else if (typeof response.body !== 'object') onError('invalid JSON response');
                    else onSuccess(response.body);
                },
                (error) => onError(typeof error === 'object' ? error.statusText : error),
            );
    }

    /**
     * Loads and updates the DSP definitions available in the library.
     * @returns Promise
     */
    loadDspDefinitions() {
        return new Promise((resolve, reject) => {
            // already loaded
            if (this.DSPs) resolve(this.DSPs);

            const onSuccess = (json) => {
                this.DSPs = json;
                Object.keys(this.DSPs).forEach((key) => this.DSPs[key].root = `${BASEURL}/${this.DSPs[key].root}`);
                resolve(this.DSPs);
            }
            const onError = (e) => {
                console.error('Failed to fetch the list of available DSP:', e);
                reject(e);
            }

            this.fetchJSON(`${BASEURL}/index.json`, onSuccess, onError);
        });
    }

   /**
     * Loads the DSP json file and returns all user interface parameters found.
     * @param {string} url - The DSP json URL.
     * @returns Promise
     */
    getDspParamFromJSON(url) {
        return new Promise((resolve, reject) => {
            this.fetchJSON(
                url,
                (json) => resolve(json.ui[0].items),
                (e) => {
                    console.error('Failed to fetch the DSP parameters:', e);
                    reject(e);
                });
        });
    }

    /**
     * Returns an array of "options" for each DSPs stored in library.
     * option = dsp name as label and dsp URL as value
     * @returns Promise
     */
    listDspAsOptions() {
        return new Promise((resolve) => {
            const options = [];
            this.loadDspDefinitions().then(
                (json) => {
                    const keys = Object.keys(json);
                    keys.forEach((key) => {
                        options.push({ value: json[key].root, label: json[key].name, group: json[key].type });
                    });
                    resolve(options);
                },
                (e) => resolve(options),
            )
        });
    }

    async listParamsFromCode(code) {
        if (code.search('stdfaust.lib') === -1) code = `import("stdfaust.lib");\n${code}`;

        const compiler = new FaustCompiler(this.libFaust);
        const generator = new FaustMonoDspGenerator();

        await generator.compile(compiler, 'dsp', code, '');
        // const ctx = new (window.AudioContext || window.webkitAudioContext)();
        // const node = await generator.createNode(ctx, 'dsp');

        return node.fInputsItems;
    }

    /**
     * Get the list of all parameters in the specified DSP.
     * @param {string|object} asset — The DSP root URL or DSP-related media object
     * @returns Promise
     */
    listParamsFromAsset(asset) {
        return new Promise((resolve, reject) => {
            const url = typeof asset === 'object' ? asset.url : asset;

            // load meta from internal DSP (in library)
            // or external DSP
            if (url.startsWith(BASEURL)) {
                if (!this.DSPs) reject('DSPs list not found')

                const dsp = Object.keys(this.DSPs).find(key => this.DSPs[key].root === url);
                this.getDspParamFromJSON(`${url}/${dsp}.json`).then(
                    (params) => resolve(params),
                    reject,
                );
            }
            else {
                this.fetchJSON(
                    `${url}?pattern=.json`, (files) => {
                        files = files.filter((f) => f.indexOf('/') === -1 && f.indexOf('_effect') === -1);
                        if (!files) reject('the DSP json file cannot be found');
                        else this.getDspParamFromJSON(`${url}/${files[0]}`).then(
                            (params) => resolve(params),
                            reject,
                        );
                    },
                    reject
                );
            }
        });
    }

    async listDspParams(type, dsp) {
        let params = []
        try {
            if (type === 'code')  params = await this.listParamsFromCode(dsp);
            if (type === 'asset') params = await this.listParamsFromAsset(dsp);
        }
        catch (e) { console.warn('DSP parameter enumeration failed:', e); }
        return params;
    }

    /**
     * Format the given FAUST parameter to match the current field definition format.
     *
     * @param {string} prefix - The FAUST dynamic field prefix.
     * @param {string|Object} container - The field container.
     * @param {Object} param - The FAUST parameter object or field value.
     * @returns Object or null
     */
    getFieldDefinition(prefix, container, param) {
        let desc = '';
        
        if (param.address) desc += `FAUST parameter address: ${param.address}`;
        if (param.init) desc += `\n- initial value: ${param.init}`;
        if (param.min) desc += `\n- minimum value: ${param.min}`;
        if (param.mas) desc += `\n- maximum value: ${param.max}`;
        if (param.step) desc += `\n- step: ${param.step}`;

        // use param.step for FAUST parameter to define the standard value type
        // or use param.value from saved field
        const standard = param.step || param.value;
        let widget = 'float'; // by default
        if (standard) {
            if (typeof standard === 'number' && standard % 1 === 0) widget = 'int'; // int
            if (typeof standard === 'string') widget = 'string'; // in case
        }

        if (!param.name) param.name = `${prefix}${param.shortname}`;
        if (!param.label) param.label = param.name.replace(prefix, '').replace('_', ' ');
        if (!param.init) param.init = widget === 'string' ? '' : 0;

        return {
            name: param.name,
            dynamicField: true,
            container: container,
            type: "field",
            widget: widget,
            coreFormat: widget.charAt(0).toUpperCase() + widget.slice(1),
            default: param.init,
            label: param.label,
            description: desc,
            conditions: [],
            connection: {
                in: { pluggable: true, default: false },
                out: { pluggable: true, default: false },
                activate: { pluggable: true, default: true, force: false },
            },
        };
    }
}

export let faust = new FaustStore()